var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-btn', {
    staticClass: "primary lighten-1",
    attrs: {
      "color": "white",
      "large": "",
      "icon": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "fab": ""
    },
    on: {
      "click": function click($event) {
        return _vm.refresh();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-refresh")])], 1), _c('v-card', {
    staticClass: "elevation-2 ma-0 pa-2",
    staticStyle: {
      "position": "relative",
      "height": "100%"
    }
  }, [_c('GmapMap', {
    ref: "GmapMap",
    staticStyle: {
      "display": "flex",
      "flex-flow": "column",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "options": _vm.options,
      "center": _vm.center,
      "zoom": _vm.zoom
    }
  }, [_vm.location ? _c('GmapCustomMarker', {
    attrs: {
      "marker": _vm.location
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.openBox(_vm.box);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "error",
      "large": ""
    }
  }, [_vm._v("mdi-map-marker")])], 1) : _vm._e(), _vm._l(_vm.boxes, function (box) {
    var _box$label, _vm$customer, _vm$customer2;

    return _c('GmapCustomMarker', {
      key: box.id,
      staticClass: "cursor-pointer",
      attrs: {
        "marker": {
          lat: box.lat,
          lng: box.lng
        }
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.openBox(box);
        }
      }
    }, [_c('div', [_c('div', {
      staticClass: "text-center"
    }, [_c('v-icon', {
      attrs: {
        "color": _vm.getBoxColor(box),
        "large": ""
      }
    }, [_vm._v("mdi-map-marker")]), box.isHere() ? _c('v-icon', {
      attrs: {
        "color": "primary",
        "medium": ""
      }
    }, [_vm._v("mdi-ferry")]) : _vm._e(), _vm.zoom >= 17 ? _c('v-alert', {
      staticClass: "mt-1 px-2 py-1 rounded box_marker",
      attrs: {
        "outlined": "",
        "color": "secondary"
      }
    }, [_c('div', [_vm._v(_vm._s((_box$label = box === null || box === void 0 ? void 0 : box.label) !== null && _box$label !== void 0 ? _box$label : '?'))]), _c('v-icon', {
      attrs: {
        "color": box.getElecColor((_vm$customer = _vm.customer) === null || _vm$customer === void 0 ? void 0 : _vm$customer.getElecTypeKind()),
        "medium": ""
      }
    }, [_vm._v("mdi-flash")]), _c('div', {
      staticClass: "d-inline caption"
    }, [_vm._v(_vm._s(_vm.customerElecLabel))]), _c('v-icon', {
      attrs: {
        "color": box.getWaterColor((_vm$customer2 = _vm.customer) === null || _vm$customer2 === void 0 ? void 0 : _vm$customer2.getWaterTypeKind()),
        "medium": ""
      }
    }, [_vm._v("mdi-water")])], 1) : _vm._e()], 1)])]);
  })], 2)], 1), _c('v-overlay', {
    staticStyle: {
      "z-index": "8"
    },
    attrs: {
      "value": _vm.overlay
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }