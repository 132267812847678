



















































import { Component, Vue, Watch } from "vue-property-decorator";

import { EnergyBox } from "@/models/EnergyBox";
import energyBoxesStore from "@/stores/energyBoxesStore";

import { Customer } from "@/models/Customer";
import { Plug } from "@/models/Plug";
import usersStore from "@/stores/usersStore";

import { routeToBox } from "@/router/router";

import mapService from "@/services/mapService";

@Component
export default class History extends Vue {
  overlay: boolean = false;

  lastUpdateMs: number = 0;
  userState = usersStore.state;
  energyBoxesState = energyBoxesStore.state;

  location: { lat: number; lng: number } | null = null;
  center = { lat: 0, lng: 0 };

  options = {
    zoomControl: false,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
    styles: [],
    clickableIcons: false,
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  map: any = null;
  zoom: number = 19;

  @Watch("boxes")
  boxesChanged(): void {
    this.lastUpdateMs = Date.now();
  }

  refreshCenter(): void {
    for (const box of this.boxes) {
      if (box.isHere()) {
        this.center = {lat: box.lat, lng: box.lng};
        return;
      }
    }

    // if (this.location != null) {
    //   this.center = this.location;
    //   return;
    // }

    // if (this.boxes.length > 0) {
    //   const box = this.boxes[0];
    //   this.center = {lat: box.lat, lng: box.lng};
    //   return;
    // }

    for (const box of this.boxes) {
      if (box.localisation == 1) {
        this.center = {lat: box.lat, lng: box.lng};
        return;
      }
    }
  }

  getBoxColor(box: EnergyBox): string {
    return box.getBoxColor(this.lastUpdateMs);
  }

  get boxes(): EnergyBox[] {
    return this.energyBoxesState.boxes;
  }

  get customer(): Customer | null {
    return this.userState.customer;
  }

  get customerElecLabel(): string | undefined {
    if (!this.customer) {
      return undefined;
    }
    return Plug.prototype.getElecLabel(this.customer?.getElecTypeKind());
  }

  openBox(box: EnergyBox): void {
    routeToBox(box.localisation);
  }

  async refresh(): Promise<void> {
    this.overlay = true;
    try {
      this.location = (
        await Promise.all([
          mapService.getCurrentLocation(),
          usersStore.refresh(),
          energyBoxesStore.refresh(),
        ])
      )[0];
    } catch (e) {
      this.errorNotify("Impossible de lister les bornes", e as Error);
    } finally {
      this.overlay = false;
    }
  }

  async mounted(): Promise<void> {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    (this.$refs?.GmapMap as any).$mapPromise?.then((map: any) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
      const google = (window as any).google;
      google.maps.event.addListener(map, "zoom_changed", () => {
        this.zoom = map.getZoom();
      });
    });
    await this.refresh();
    this.refreshCenter();
  }
}
