class MapService {
  getCurrentLocation(): Promise<{ lat: number; lng: number } | null> {
    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        // console.log("navigator.geolocation")
        resolve(null);
      }

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          // console.log("ok")
          resolve({ lat: coords.latitude, lng: coords.longitude });
        },
        () => {
          // console.log("resolve(null)")
          resolve(null);
        }
      );
    });
  }
}

export default new MapService();
